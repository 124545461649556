import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"

// import app components
import Edges from "../../components/edges"
import Textarea from "../../components/textarea"
import BackgroundImage from "../../components/backgroundImage"
import { usePlaceholderQuery } from "../../components/placeholder/usePlaceholderQuery"

import TheDate from "../../components/date"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Home(props) {
  const {
    data: {
      post: { title, content, templatePost, date }
    }
  } = props
  const { allSiteOptions } = usePlaceholderQuery()

  return (
    <Container>
      <HeroEdges size="lg">
        <FeaturedImage>
          {templatePost.featuredImage ? (
            <GatsbyImage
              image={
                templatePost.featuredImage.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
            />
          ) : (
            <GatsbyImage
              image={
                allSiteOptions[0]?.themeOptions?.siteOptions
                  ?.placeholderFeaturedImage.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
            />
          )}
        </FeaturedImage>
      </HeroEdges>
      <Edges size="md">
        <Content>
          <PostDate {...{ date }} />
          <div>
            <TitleBar>
              {!!title && <Typography variant="h1">{title}</Typography>}
            </TitleBar>
            <PostContent content={content} />
          </div>
        </Content>
      </Edges>
    </Container>
  )
}
const Container = styled.div`
  background: white;
  padding-bottom: 60px;
`
const HeroEdges = styled(Edges)`
  @media (min-width: 800px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  padding-top: 15px;
  padding-bottom: 15px;
`
const FeaturedImage = styled.div`
  position: relative;
  @media (min-width: 800px) {
    min-height: 400px;
  }
  min-height: 200px;
`
const TitleBar = styled.div``

const PostDate = styled(TheDate)`
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    margin-right: 50px;
  }
`
const Content = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const PostContent = styled(Textarea)``

export const postQuery = graphql`
  query Post($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      uri
      date(formatString: "MMMM DD, YYYY")
      title
      content
      templatePost {
        fieldGroupName
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 90
                placeholder: BLURRED
                formats: AUTO
              )
            }
          }
        }
      }
    }
  }
`

// export const CollectionQuery = graphql`
//   query DefaultSinglePost($id: Int!) {
//     wordsbyCollections(ID: { eq: $id }) {
//       title
//       date
//       content
//       acf {
//         featured_image {
//           url {
//             childImageSharp {
//               fluid(maxWidth: 2560, quality: 90) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
